<template>
  <general-data-list type="paymentPeriod" />
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BButton,
  BFormRadio,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import { required, between } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import useJwt from "@/auth/jwt/useJwt";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { parseDateToString, updateUserConfiguration } from "@/auth/utils";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import SelectDateTime from "@/views/components/SelectDateTime.vue";
import GeneralDataList from "../../general-data/list/GeneralDataList.vue";

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormRadio,
    BFormGroup,
    BFormInput,
    SelectDateTime,
    GeneralDataList,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      data: {
        autoIssueInvoice: false,
        useViettelInvoice: false,
        viettelInvoiceUsername: "",
        viettelInvoicePassword: "",
        sellerLegalName: "",
        sellerTaxCode: "",
        sellerAddressLine: "",
        sellerEmail: "",
        sellerPhoneNumber: "",
        sellerFaxNumber: "",
        sellerBankName: "",
        sellderBankAccount: "",
        sellerWebsite: "",
        templateCode: "",
        invoiceSeries: "",
      },

      // validation
      required,
      between,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation();

    const { t } = useI18nUtils();
    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      t,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      useJwt
        .getUserConfiguration()
        .then((response) => {
          updateUserConfiguration(response.data);
          this.data = response.data;
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Thông báo`,
              icon: "UserIcon",
              variant: "danger",
              text: `Có lỗi xảy ra khi lấy thông tin cài đặt. Vui lòng thử lại sau`,
            },
          });
        });
    },

    onSubmit() {
      const data = new FormData();
      data.append("useViettelInvoice", this.data.useViettelInvoice);
      data.append("autoIssueInvoice", this.data.autoIssueInvoice);
      data.append("viettelInvoiceUsername", this.data.viettelInvoiceUsername);
      data.append("viettelInvoicePassword", this.data.viettelInvoicePassword);
      data.append("sellerLegalName", this.data.sellerLegalName);
      data.append("sellerTaxCode", this.data.sellerTaxCode);
      data.append("sellerAddressLine", this.data.sellerAddressLine);
      data.append("sellerPhoneNumber", this.data.sellerPhoneNumber);
      data.append("sellerEmail", this.data.sellerEmail);
      data.append("sellerFaxNumber", this.data.sellerFaxNumber);
      data.append("sellerBankName", this.data.sellerBankName);
      data.append("sellerBankAccount", this.data.sellerBankAccount);
      data.append("sellerWebsite", this.data.sellerWebsite);
      data.append("templateCode", this.data.templateCode);
      data.append("invoiceSeries", this.data.invoiceSeries);

      useJwt
        .updateUserConfiguration(data)
        .then((response) => {
          updateUserConfiguration(response.data);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Thông báo`,
              icon: "UserIcon",
              variant: "success",
              text: `Thông tin đã được cập nhật thành công`,
            },
          });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status === 422 &&
            error.response.data &&
            error.response.data.errors
          ) {
            this.$refs.refFormObserver.setErrors(error.response.data.errors);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Thông báo",
                text: error.response.data.message
                  ? error.response.data.message
                  : "Có lỗi xảy ra, vui lòng thử lại sau",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    },
  },
};
</script>
