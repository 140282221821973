<template>
  <validation-observer
    #default="{ handleSubmit, invalid }"
    ref="refFormObserver"
  >
    <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
      <b-row>
        <b-col>
          <image-view
            v-model="item.brandLogoFile"
            :image-src="item.brandLogo"
            label="Logo thương hiệu"
            :src="item.brandLogo"
          />
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="12">
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="item.useSystemEmail"
              name="useSystemEmail"
              :value="true"
              class="custom-control-primary"
            >
              {{ t("Email mặc định của hệ thống") }}
            </b-form-radio>

            <b-form-radio
              v-model="item.useSystemEmail"
              name="useSystemEmail"
              :value="false"
              class="custom-control-primary"
            >
              {{ t("Email tùy chỉnh") }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="item.useSystemEmail === false">
        <b-col cols="6">
          <validation-provider
            #default="validationContext"
            name="customMailDisplayName"
            rules="required"
          >
            <b-form-group>
              <template v-slot:label>
                {{ t("Tên hiển thị") }} <span class="text-danger">(*)</span>
              </template>
              <b-input-group>
                <b-form-input
                  id="customMailDisplayName"
                  v-model="item.customMailDisplayName"
                  placeholder="Trường học EDU"
                  trim
                  :state="getValidationState(validationContext)"
                />
              </b-input-group>
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <validation-provider
            #default="validationContext"
            name="customMailAddress"
            rules="required|email"
          >
            <b-form-group>
              <template v-slot:label>
                {{ t("Địa chỉ hiển thị") }} <span class="text-danger">(*)</span>
              </template>
              <b-input-group>
                <b-form-input
                  id="customMailAddress"
                  v-model="item.customMailAddress"
                  placeholder="info@tingee.vn"
                  trim
                  :state="getValidationState(validationContext)"
                />
              </b-input-group>
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- submit -->
      </b-row>
      <b-row v-if="item.useSystemEmail === false">
        <b-col cols="6">
          <validation-provider
            #default="validationContext"
            name="customMailHost"
            rules="required"
          >
            <b-form-group>
              <template v-slot:label>
                {{ t("Host") }} <span class="text-danger">(*)</span>
              </template>
              <b-input-group>
                <b-form-input
                  id="customMailHost"
                  v-model="item.customMailHost"
                  placeholder="smtp.gmail.com"
                  trim
                  :state="getValidationState(validationContext)"
                />
              </b-input-group>
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <validation-provider
            #default="validationContext"
            name="customMailPort"
            rules="required"
          >
            <b-form-group>
              <template v-slot:label>
                {{ t("Port") }} <span class="text-danger">(*)</span>
              </template>
              <b-input-group>
                <b-form-input
                  id="customMailPort"
                  v-model="item.customMailPort"
                  placeholder="587"
                  trim
                  :state="getValidationState(validationContext)"
                />
              </b-input-group>
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- submit -->
      </b-row>
      <b-row v-if="item.useSystemEmail === false">
        <b-col cols="6">
          <validation-provider
            #default="validationContext"
            name="customMailUsername"
            rules="required"
          >
            <b-form-group>
              <template v-slot:label>
                {{ t("Tên đăng nhập") }} <span class="text-danger">(*)</span>
              </template>
              <b-input-group>
                <b-form-input
                  id="customMailUsername"
                  v-model="item.customMailUsername"
                  placeholder="info@tingee.vn"
                  trim
                  :state="getValidationState(validationContext)"
                />
              </b-input-group>
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <validation-provider
            #default="{ errors }"
            name="customMailPassword"
            rules="required"
          >
            <b-form-group>
              <template v-slot:label>
                {{ t("Mật khẩu") }} <span class="text-danger">(*)</span>
              </template>
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="customMailPassword"
                  v-model="item.customMailPassword"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false : null"
                  name="password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- submit -->
      </b-row>
      <b-button
        class="mt-2"
        variant="primary"
        block
        type="submit"
        :disabled="invalid"
      >
        {{ t("Lưu") }}
      </b-button>
      <!-- <div v-if="item.useSystemEmail === false">
        <hr />

        <b-form-group :label="t('Kiểm tra cấu hình bằng việc gửi email test')">
          <b-input-group>
            <b-form-input placeholder="phongnn@tingee.vn" />
            <b-input-group-append>
              <b-button variant="outline-primary">
                <feather-icon icon="SendIcon" class="mr-50" />
                {{ t("Gửi mail test") }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div> -->
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BButton,
  BInputGroupAppend,
  BFormRadio,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import toast from "@/utils/toast";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { updateUserConfiguration } from "@/auth/utils";
import ImageView from "@/views/components/ImageView.vue";

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BFormRadio,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton,
    BInputGroupAppend,
    ValidationObserver,
    ValidationProvider,
    ImageView,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      item: {
        brandLogoFile: null,
        brandLogo: "",
        useSystemEmail: true,
        customMailAddress: "",
        customMailDisplayName: "",
        customMailHost: "",
        customMailPort: null,
        customMailUsername: "",
        customMailPassword: "",
      },
      // validation
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation();
    const toastification = toast();
    const { t } = useI18nUtils();
    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      toastification,
      t,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      useJwt
        .getUserConfiguration()
        .then((response) => {
          updateUserConfiguration(response.data);
          this.item = response.data;
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Thông báo`,
              icon: "UserIcon",
              variant: "danger",
              text: `Có lỗi xảy ra khi lấy thông tin cài đặt. Vui lòng thử lại sau`,
            },
          });
        });
    },
    onSubmit() {
      const data = new FormData();
      if (this.item.brandLogoFile) {
        data.append("brandLogo", this.item.brandLogoFile);
      }
      data.append("useSystemEmail", this.item.useSystemEmail ?? false);
      data.append(
        "customMailDisplayName",
        this.item.customMailDisplayName ?? ""
      );
      data.append("customMailAddress", this.item.customMailAddress ?? "");
      data.append("customMailHost", this.item.customMailHost ?? "");
      data.append("customMailPort", this.item.customMailPort ?? 0);
      data.append("customMailUsername", this.item.customMailUsername ?? "");
      data.append("customMailPassword", this.item.customMailPassword ?? "");

      useJwt
        .updateUserConfiguration(data)
        .then((response) => {
          updateUserConfiguration(response.data);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Thông báo`,
              icon: "UserIcon",
              variant: "success",
              text: `Thông tin đã được cập nhật thành công`,
            },
          });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status === 422 &&
            error.response.data &&
            error.response.data.errors
          ) {
            this.$refs.refFormObserver.setErrors(error.response.data.errors);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Thông báo",
                text: error.response.data.message
                  ? error.response.data.message
                  : "Có lỗi xảy ra, vui lòng thử lại sau",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    },
  },
};
</script>
