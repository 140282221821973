import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchGeneralDatas(ctx, params) {
      return useJwt.getGeneralDatas(params).then(response => response);
    },
    createGeneralData(ctx, data) {
      return useJwt.createGeneralData(data).then(response => response);
    },
    updateGeneralData(ctx, data) {
      return useJwt.updateGeneralData(data).then(response => response);
    },
    getGeneralDataDetail(ctx, id) {
      return useJwt.getGeneralDataDetail(id).then(response => response);
    },
    deleteGeneralDatas(ctx, data) {
      return useJwt.deleteGeneralDatas(data).then(response => response);
    },
  },
};
