<template>
  <validation-observer
    #default="{ handleSubmit, invalid }"
    ref="refFormObserver"
  >
    <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
      <b-row class="mt-2">
        <b-col cols="12">
          <span>{{ t("Tách hóa đơn theo khoản thu và tài khoản thanh toán") }}</span>
          <small class="text-danger"><br>LƯU Ý: Chỉ áp dụng trong trường hợp import hóa đơn, không áp dụng trong trường hợp lập hóa đơn riêng lẻ mà có từ 2 tài khoản thu khác nhau trong danh sách dịch vụ</small>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.groupInvoiceByFeeAndTingee"
              name="groupInvoiceByFeeAndTingee"
              :value="true"
              class="custom-control-primary"
            >
              {{ t("Bật") }}
            </b-form-radio>

            <b-form-radio
              v-model="data.groupInvoiceByFeeAndTingee"
              name="groupInvoiceByFeeAndTingee"
              :value="false"
              class="custom-control-primary"
            >
              {{ t("Tắt") }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <span>{{ t("Tự động sử dụng tiền thừa vào hóa đơn kỳ sau") }}</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.autoUsePrepaid"
              name="autoUsePrepaid"
              :value="true"
              class="custom-control-primary"
            >
              {{ t("Bật") }}
            </b-form-radio>

            <b-form-radio
              v-model="data.autoUsePrepaid"
              name="autoUsePrepaid"
              :value="false"
              class="custom-control-primary"
            >
              {{ t("Tắt") }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <span>{{ t("Sử dụng hóa đơn SInvoice") }}</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.useViettelInvoice"
              name="useViettelInvoice"
              :value="true"
              class="custom-control-primary"
            >
              {{ t("Bật") }}
            </b-form-radio>

            <b-form-radio
              v-model="data.useViettelInvoice"
              name="useViettelInvoice"
              :value="false"
              class="custom-control-primary"
            >
              {{ t("Tắt") }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="data.useViettelInvoice" class="mt-1">
        <b-col>
          <validation-provider
            #default="validationContext"
            name="autoNotificationNextBillingDateAt"
            rules="required"
          >
            <b-form-group>
              <template v-slot:label>
                {{ t("Tên đăng nhập") }}<span class="text-danger"> (*) </span>
              </template>
              <b-form-input
                id="viettelInvoiceUsername"
                v-model="data.viettelInvoiceUsername"
                placeholder=""
                trim
                :state="getValidationState(validationContext)"
              />
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            #default="validationContext"
            name="autoNotificationNextBillingDateAt"
            rules="required"
          >
            <b-form-group>
              <template v-slot:label>
                {{ t("Mật khẩu") }}<span class="text-danger"> (*) </span>
              </template>
              <b-form-input
                id="viettelInvoicePassword"
                v-model="data.viettelInvoicePassword"
                placeholder=""
                trim
                :state="getValidationState(validationContext)"
              />
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            #default="validationContext"
            name="templateCode"
            rules="required"
          >
            <b-form-group>
              <template v-slot:label>
                {{ t("Mã hóa đơn") }}<span class="text-danger"> (*) </span>
              </template>
              <b-form-input
                id="templateCode"
                v-model="data.templateCode"
                placeholder=""
                trim
                :state="getValidationState(validationContext)"
              />
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            #default="validationContext"
            name="invoiceSeries"
            rules="required"
          >
            <b-form-group>
              <template v-slot:label>
                {{ t("Số series hóa đơn")
                }}<span class="text-danger"> (*) </span>
              </template>
              <b-form-input
                id="invoiceSeries"
                v-model="data.invoiceSeries"
                placeholder=""
                trim
                :state="getValidationState(validationContext)"
              />
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row v-if="data.useViettelInvoice" class="mt-1">
        <b-col>
          <validation-provider
            #default="validationContext"
            name="sellerLegalName"
            rules="required"
          >
            <b-form-group>
              <template v-slot:label>
                {{ t("Tên doanh nghiệp") }}
                <span class="text-danger"> (*) </span>
              </template>
              <b-form-input
                id="sellerLegalName"
                v-model="data.sellerLegalName"
                placeholder=""
                trim
                :state="getValidationState(validationContext)"
              />
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            #default="validationContext"
            name="sellerTaxCode"
            rules="required"
          >
            <b-form-group>
              <template v-slot:label>
                {{ t("MST bên bán") }}<span class="text-danger"> (*) </span>
              </template>
              <b-form-input
                id="sellerTaxCode"
                v-model="data.sellerTaxCode"
                placeholder=""
                trim
                :state="getValidationState(validationContext)"
              />
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            #default="validationContext"
            name="sellerAddressLine"
            rules="required"
          >
            <b-form-group>
              <template v-slot:label>
                {{ t("Địa chỉ bên bán") }}<span class="text-danger"> (*) </span>
              </template>
              <b-form-input
                id="sellerAddressLine"
                v-model="data.sellerAddressLine"
                placeholder=""
                trim
                :state="getValidationState(validationContext)"
              />
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row v-if="data.useViettelInvoice" class="mt-1">
        <b-col>
          <validation-provider
            #default="validationContext"
            name="sellerPhoneNumber"
            rules=""
          >
            <b-form-group>
              <template v-slot:label>
                {{ t("SĐT bên bán") }}
              </template>
              <b-form-input
                id="sellerPhoneNumber"
                v-model="data.sellerPhoneNumber"
                placeholder=""
                trim
                :state="getValidationState(validationContext)"
              />
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            #default="validationContext"
            name="sellerFaxNumber"
            rules=""
          >
            <b-form-group>
              <template v-slot:label>
                {{ t("Số fax bên bán") }}
              </template>
              <b-form-input
                id="sellerFaxNumber"
                v-model="data.sellerFaxNumber"
                placeholder=""
                trim
                :state="getValidationState(validationContext)"
              />
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            #default="validationContext"
            name="sellerEmail"
            rules=""
          >
            <b-form-group>
              <template v-slot:label>
                {{ t("Email bên bán") }}
              </template>
              <b-form-input
                id="sellerEmail"
                v-model="data.sellerEmail"
                placeholder=""
                trim
                :state="getValidationState(validationContext)"
              />
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row v-if="data.useViettelInvoice" class="mt-1">
        <b-col>
          <validation-provider
            #default="validationContext"
            name="sellerBankName"
            rules=""
          >
            <b-form-group>
              <template v-slot:label>
                {{ t("Tên ngân hàng bên bán") }}
              </template>
              <b-form-input
                id="sellerBankName"
                v-model="data.sellerBankName"
                placeholder=""
                trim
                :state="getValidationState(validationContext)"
              />
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            #default="validationContext"
            name="sellerBankAccount"
            rules=""
          >
            <b-form-group>
              <template v-slot:label>
                {{ t("Số TK bên bán") }}
              </template>
              <b-form-input
                id="sellerBankAccount"
                v-model="data.sellerBankAccount"
                placeholder=""
                trim
                :state="getValidationState(validationContext)"
              />
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            #default="validationContext"
            name="sellerWebsite"
            rules=""
          >
            <b-form-group>
              <template v-slot:label>
                {{ t("Website bên bán") }}
              </template>
              <b-form-input
                id="sellerWebsite"
                v-model="data.sellerWebsite"
                placeholder=""
                trim
                :state="getValidationState(validationContext)"
              />
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="mt-2" v-if="data.useViettelInvoice">
        <b-col cols="12">
          <span>{{
            t("Tự động xuất hóa đơn sau khi khách hàng thanh toán")
          }}</span>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="data.autoIssueInvoice"
              name="autoIssueInvoice"
              :value="true"
              class="custom-control-primary"
            >
              {{ t("Bật") }}
            </b-form-radio>

            <b-form-radio
              v-model="data.autoIssueInvoice"
              name="autoIssueInvoice"
              :value="false"
              class="custom-control-primary"
            >
              {{ t("Tắt") }}
            </b-form-radio>
          </div>
        </b-col>
      </b-row>

      <b-button
        class="mt-2"
        variant="primary"
        block
        type="submit"
        :disabled="invalid"
      >
        {{ t("Lưu") }}
      </b-button>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BButton,
  BFormRadio,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import { required, between } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import useJwt from "@/auth/jwt/useJwt";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { parseDateToString, updateUserConfiguration } from "@/auth/utils";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import SelectDateTime from "@/views/components/SelectDateTime.vue";

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormRadio,
    BFormGroup,
    BFormInput,
    SelectDateTime,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      data: {
        autoIssueInvoice: false,
        useViettelInvoice: false,
        viettelInvoiceUsername: "",
        viettelInvoicePassword: "",
        sellerLegalName: "",
        sellerTaxCode: "",
        sellerAddressLine: "",
        sellerEmail: "",
        sellerPhoneNumber: "",
        sellerFaxNumber: "",
        sellerBankName: "",
        sellderBankAccount: "",
        sellerWebsite: "",
        templateCode: "",
        invoiceSeries: "",
        autoUsePrepaid: false,
        groupInvoiceByFeeAndTingee: false
      },

      // validation
      required,
      between,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation();

    const { t } = useI18nUtils();
    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      t,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      useJwt
        .getUserConfiguration()
        .then((response) => {
          updateUserConfiguration(response.data);
          this.data = response.data;
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Thông báo`,
              icon: "UserIcon",
              variant: "danger",
              text: `Có lỗi xảy ra khi lấy thông tin cài đặt. Vui lòng thử lại sau`,
            },
          });
        });
    },

    onSubmit() {
      const data = new FormData();
      data.append("useViettelInvoice", this.data.useViettelInvoice ?? false);
      data.append("autoIssueInvoice", this.data.autoIssueInvoice ?? false);
      data.append(
        "viettelInvoiceUsername",
        this.data.viettelInvoiceUsername ?? ""
      );
      data.append(
        "viettelInvoicePassword",
        this.data.viettelInvoicePassword ?? ""
      );
      data.append("sellerLegalName", this.data.sellerLegalName ?? "");
      data.append("sellerTaxCode", this.data.sellerTaxCode ?? "");
      data.append("sellerAddressLine", this.data.sellerAddressLine ?? "");
      data.append("sellerPhoneNumber", this.data.sellerPhoneNumber ?? "");
      data.append("sellerEmail", this.data.sellerEmail ?? "");
      data.append("sellerFaxNumber", this.data.sellerFaxNumber ?? "");
      data.append("sellerBankName", this.data.sellerBankName ?? "");
      data.append("sellerBankAccount", this.data.sellerBankAccount ?? "");
      data.append("sellerWebsite", this.data.sellerWebsite ?? "");
      data.append("templateCode", this.data.templateCode ?? "");
      data.append("invoiceSeries", this.data.invoiceSeries ?? "");
      data.append("autoUsePrepaid", this.data.autoUsePrepaid ?? false);
      data.append("groupInvoiceByFeeAndTingee", this.data.groupInvoiceByFeeAndTingee ?? false);

      useJwt
        .updateUserConfiguration(data)
        .then((response) => {
          updateUserConfiguration(response.data);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Thông báo`,
              icon: "UserIcon",
              variant: "success",
              text: `Thông tin đã được cập nhật thành công`,
            },
          });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status === 422 &&
            error.response.data &&
            error.response.data.errors
          ) {
            this.$refs.refFormObserver.setErrors(error.response.data.errors);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Thông báo",
                text: error.response.data.message
                  ? error.response.data.message
                  : "Có lỗi xảy ra, vui lòng thử lại sau",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    },
  },
};
</script>
